import type { MouseEvent } from 'react'

import { Button, Text } from '@ocho/aurora'
import { IconCopy } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import Popover from '@/components/Popover'
import { Box, Flex } from '@/components/system'

import { Side } from '@/utils/constants/enums'
import useClipboard from '@/utils/hooks/useClipboard'

interface CopyProps {
  allowToCopy?: boolean

  /**
   * Function called with current status
   * @param payload - Object containing the copied state and copy function
   * Inspired by {@link https://mantine.dev/core/copy-button/}
   *
   * @example
   *  <Copy value={phone.number}>
   *    {({ copy }) => (
   *      <ValueToCopy onClick={copy}>{phone.number}</ValueToCopy>
   *    )}
   *  </Copy>
   */
  children?:
    | ((payload: {
        copied: boolean
        copy(e?: MouseEvent<HTMLButtonElement>): void
      }) => React.ReactNode)
    | React.ReactNode

  /** Icon */
  icon?: React.ReactNode

  /** Copied status timeout in ms */
  timeout?: number

  /** Title for the copy icon */
  title?: string

  /** Value that should be copied to the clipboard */
  value: number | string
}

const DEFAULT_TIMEOUT = 1000

function Copy({
  allowToCopy = true,
  children,
  icon,
  timeout = DEFAULT_TIMEOUT,
  title,
  value,
}: CopyProps) {
  const { t } = useTranslation()
  const { copy, state } = useClipboard({ timeout })

  const iconCopyTitle = title || t('copy.title')

  function handleCopy() {
    copy(value, allowToCopy)
  }

  if (!value) return null

  return (
    <Box pointerEvents="auto">
      <Popover
        trigger={
          <Flex
            alignItems="center"
            gap="var(--space--xsmall)"
            justifyContent="start"
          >
            {typeof children === 'function'
              ? children({ copied: state.copied, copy: handleCopy })
              : children}

            {allowToCopy ? (
              <Button
                onClick={handleCopy}
                $variant="tertiary"
                disabled={!allowToCopy}
                title={iconCopyTitle}
              >
                {icon ? icon : <IconCopy aria-hidden />}
              </Button>
            ) : null}
          </Flex>
        }
        open={state.copied}
        showCloseButton={false}
        side={Side.TOP}
        asChild
      >
        <Text>{t('copy.copied.successfully')}</Text>
      </Popover>
    </Box>
  )
}

export default Copy
