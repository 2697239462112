import { Nav } from '@/modules/SideNav/styled'

import { Container, Content } from './styled'

export default function AppSkeleton() {
  return (
    <Container>
      <Nav />
      <Content />
    </Container>
  )
}
