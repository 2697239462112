export const INVALID_PROPS = [
  'paddingTop',
  'paddingLeft',
  'paddingRight',
  'paddingBottom',
  'paddingX',
  'paddingY',

  'gap',
  'gridColumn',
  'gridColumnGap',
  'gridTemplateRows',
  'gridTemplateColumns',
  'gridAutoFlow',
  'gridAutoColumns',
  'gridAutoRows',

  'justifySelf',

  'borderRadius',
  'borderColor',
  'borderBottom',

  'margin',
  'marginTop',
  'marginLeft',
  'marginBottom',

  'flexDirection',
  'flexGrow',
  'flexWrap',

  'maxWidth',
  'minWidth',

  'justifyContent',
  'justifyItems',
  'backgroundColor',

  'alignSelf',
  'alignItems',
  'alignContent',

  'minHeight',

  'sticky',

  'width',

  // We should prefix these with $
  'isActive',
  'isSticky',
  'small',
  'expanded',
  'narrow',
  'isWide',
  'wide',
  'reducedLabel',
  'isLoading',
  'isBorderLess',
  'select',
]

export function shouldForwardProp(prop) {
  return !INVALID_PROPS.includes(prop)
}
