import { useState } from 'react'
import type { FormEvent } from 'react'

import { Button } from '@ocho/aurora'
import { captureUserFeedback, lastEventId } from '@sentry/react'
import { IconArrowUp, IconCheck, IconX } from '@tabler/icons-react'
import { fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth'
import { useTranslation } from 'react-i18next'

import Copy from '@/components/Copy'
import { Box, Flex } from '@/components/system'
import TextInput from '@/components/TextInput'

import { ValueToCopy } from '@/GlobalStyles'
import { HttpStatus } from '@/utils/constants/enums'

import oops from './oops.svg'
import { Container, ErrorMessage, SentryDescription } from './styled'

const MESSAGE_TIMEOUT = 3000

function AppError({ error }: { error?: any }): JSX.Element {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [isSent, setIsSent] = useState(false)

  const eventId = lastEventId()
  const isNotFound =
    error?.status === HttpStatus.NotFound ||
    error?.response?.status === HttpStatus.NotFound

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const user = await getCurrentUser().catch(() => null)
    const userAttributes = await fetchUserAttributes().catch(() => null)

    captureUserFeedback({
      comments: message,
      email: userAttributes.email,
      event_id: eventId,
      name: user.username,
    })

    setMessage('')

    setIsSent(true)
    setTimeout(() => setIsSent(false), MESSAGE_TIMEOUT)
  }

  return (
    <Container>
      <img alt="" src={oops} />
      <Box>
        <h1>
          <IconX aria-hidden />
          {isNotFound
            ? t('modules.appError.notFound.title')
            : t('modules.appError.error.title')}
        </h1>
        <ErrorMessage>
          {isNotFound ? (
            <p>{t('modules.appError.notFound.message')}</p>
          ) : (
            <>
              {Boolean(eventId) && (
                <Flex
                  flexDirection="column"
                  gap="var(--space--xsmall)"
                  marginTop="var(--space--small)"
                  padding="var(--space--xsmall)"
                >
                  <p>{t('modules.appError.error.message')}</p>
                  <Box marginTop="var(--space--medium)">
                    <SentryDescription>
                      {t('modules.appError.eventId.description')}
                    </SentryDescription>

                    <Copy value={eventId}>
                      {({ copy }) => (
                        <ValueToCopy
                          onClick={copy}
                          title={t('modules.appError.eventId.title')}
                        >
                          {eventId}
                        </ValueToCopy>
                      )}
                    </Copy>
                  </Box>
                </Flex>
              )}
              <form onSubmit={handleSubmit}>
                <TextInput
                  onChange={(e) => setMessage(e.target.value)}
                  label={t('comments')}
                  value={message}
                  multiline
                  required
                />
                <Button
                  $variant="primary"
                  alignSelf="end"
                  disabled={isSent}
                  type="submit"
                >
                  {isSent ? t('thankYou') : t('send')}
                  {isSent ? <IconCheck /> : <IconArrowUp />}
                </Button>
              </form>
            </>
          )}
        </ErrorMessage>
      </Box>
    </Container>
  )
}

export default AppError
