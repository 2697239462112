import { TagType } from '@ocho/aurora'

import type { Category } from '@/components/FiltersDropdown/types'

import { AuthEventResponses } from '@/utils/api/authentication/types'
import {
  ApplicationStatus,
  CommunicationLogName,
  ConsentStatus,
  CoverageType,
  CustomerTaskStatus,
  DocumentStatus,
  DocumentType,
  DriverLicenseStatus,
  EndorsementTypes,
  EntityStatus,
  InsuranceStatus,
  IssuerName,
  LoanEventType,
  PaymentAccountsMethod,
  PaymentsScheduleStatus,
  ProductAccountStatus,
  ProductStatus,
  Purpose,
  RateCall,
  ReconciliationStatus,
  StatusIconType,
  TaskStatus,
  TaskTypes,
  TimeZones,
  TransactionPurpose,
  TransactionStatus,
} from '@/utils/constants/enums'

export const US_PHONE_PREFIX = '+1'

export const US_COUNTRY_CODE = 'US'

// FIXME: use env variable
export const DEFAULT_USER_PASSWORD = 'U_JzVuS0fxllVG"F'

export const PUSHER_CLIENT_ID = 'c2e35eaff12c96f20ae1'
export const PUSHER_CLUSTER = 'us3'
export const MIN_LICENSE_NUMBER_LENGTH = 5
export const MAX_LICENSE_NUMBER_LENGTH = 15
export const VIN_LENGTH = 17

export const OWNER_ID_POLICY_FORMAT_START_REGEX = /^00000000/

export const DOCUMENTS_TRANSLATE_PREFIX = 'documentTypes'
export const DOCUMENTS_TRANSLATE_GLOBAL_PREFIX =
  'customer.screen.products.tabs.documents'

export const DATE_FORMAT = {
  en: 'MM/dd/yyyy',
  enAPI: 'yyyy-MM-dd',
  enShort: 'MMM dd, yyyy',
  enTime: 'MM/dd/yyyy p',
  humanDateFormat: 'LLL do, yyyy',
  humanDateFormatTime: 'LLL do, yyyy p',
  regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
  regexEn: /^\d{2}\/\d{2}\/\d{4}$/,
}

export function getCleanAmount(number: number | string) {
  return number ? Number(String(number).replace(/,/g, '')) : 0
}
export const EMPTY_ARRAY = Object.freeze([])

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MILES_PER_DAY_OPTIONS = [13, 17, 24, 34, 41]

export const TIMEOUT_PROMPT = 60000
export const TIMEOUT_LOGOUT = 900000
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const TIMEOUT_SECONDS = TIMEOUT_PROMPT / 1000

export const TIMEOUT_PUSHER = 20000

export const JSON_SPACING = 2

export const APPLICATION_STATUS_FOR_RESET_FINAL_QUOTE = [
  ApplicationStatus.LOAN_PREMIUM_FINANCE_SIGNATURE_PENDING,
  ApplicationStatus.LOAN_PREMIUM_FINANCE_SIGNED,
  ApplicationStatus.POLICY_GENERATION_AGENT_LOCKED,
  ApplicationStatus.POLICY_GENERATION_PENDING,
  ApplicationStatus.POLICY_SIGNATURE_PENDING,
  ApplicationStatus.QUOTES_FINAL,
  ApplicationStatus.QUOTES_FINAL_ACCEPTED,
  ApplicationStatus.QUOTES_FINAL_AGENT_LOCKED,
  ApplicationStatus.QUOTES_FINAL_BOUNCED,
  ApplicationStatus.QUOTES_FINAL_MORE_INFO_NEEDED,
]

export const APPLICATION_STATUS_FOR_VEHICLE_DISCOVERY_CHANGE = [
  ApplicationStatus.DRIVER_INCIDENT_DISCOVERY,
  ApplicationStatus.ISSUER_QUESTIONS,
  ApplicationStatus.LOAN_PREMIUM_FINANCE_SIGNATURE_PENDING,
  ApplicationStatus.LOAN_PREMIUM_FINANCE_SIGNED,
  ApplicationStatus.POLICY_GENERATION_AGENT_LOCKED,
  ApplicationStatus.POLICY_GENERATION_PENDING,
  ApplicationStatus.PROOF_DOCUMENTATION,
  ApplicationStatus.PROOF_DOCUMENTATION_PENDING,
  ApplicationStatus.QUOTES_FINAL,
  ApplicationStatus.QUOTES_FINAL_ACCEPTED,
  ApplicationStatus.QUOTES_FINAL_AGENT_LOCKED,
  ApplicationStatus.QUOTES_FINAL_BOUNCED,
  ApplicationStatus.QUOTES_FINAL_PENDING,
  ApplicationStatus.QUOTES_RATE_CALL1_5,
  ApplicationStatus.QUOTES_RATE_CALL1_5_PENDING,
]

export const APPLICATION_STATUSES_CATEGORIES: Category[] = [
  {
    filters: [
      ApplicationStatus.QUOTES_FINAL_AGENT_LOCKED,
      ApplicationStatus.QUOTES_FINAL_PENDING,
      ApplicationStatus.QUOTES_FINAL_BOUNCED,
    ],
    label: 'quotes_final',
  },
  {
    filters: [
      ApplicationStatus.POLICY_GENERATION_AGENT_LOCKED,
      ApplicationStatus.POLICY_GENERATION_PENDING,
    ],
    label: 'policy',
  },
]

export const getDocumentsCategories = (filters: DocumentType[]): Category[] => [
  { filters, label: 'documents' },
]

export const APPLICATION_POLICY_DOCUMENT_TYPES = [
  DocumentType.IdDriverLicense,
  DocumentType.InsurancePolicyAuto,
  DocumentType.InsurancePolicyAutoEvidence,
  DocumentType.InsurancePremiumFinanceLoanContract,
  DocumentType.PaymentOrderReceipt,
  DocumentType.Signature,
  DocumentType.SignatureInitial,
]

export const APPLICATION_POLICY_DOCUMENTS_CATEGORIES = getDocumentsCategories(
  APPLICATION_POLICY_DOCUMENT_TYPES,
)

export const LOCK_APPLICATION_FILTERED_BY = [
  ApplicationStatus.QUOTES_FINAL_PENDING,
  ApplicationStatus.POLICY_GENERATION_PENDING,
]

// @see {@link https://styled-components.com/docs/api#shouldforwardprop}
// @see {@link https://github.com/styled-components/styled-components/issues/439}
export const FORWARDED_PROPS = {
  flexbox: ['flexDirection'],
}

export const MIN_SEARCH_CHARACTERS = 3
export const INITIAL_SEARCH_MATCH_REGEX = /^[a-zA-Z0-9+]/
export const PHONE_NUMBER_MATCH_REGEX = /^\+?[0-9\-() ]+$/
export const CUSTOMER_NAME_PHONE_NUMBER_MATCH_REGEX = new RegExp(
  '^(\\+?\\d+|[a-zA-Z ]+)$',
)

export const isPartner = Boolean(import.meta.env.VITE_IS_PARTNER)

export const BOOLEAN_VALUES_FOR_TAGS = {
  false: TagType.Danger,
  true: TagType.Primary,
}

export const DRIVER_LICENSE_STATUS_FOR_TAGS = {
  [DriverLicenseStatus.Active]: TagType.Secondary,
  [DriverLicenseStatus.Expired]: TagType.Danger,
  [DriverLicenseStatus.Pending]: TagType.Warning,
  [DriverLicenseStatus.Revoked]: TagType.Danger,
  [DriverLicenseStatus.Suspended]: TagType.Danger,
  [DriverLicenseStatus.Temporary]: TagType.Neutral,
}

export const TRANSACTION_STATUS_FOR_TAGS = {
  [TransactionStatus.Error]: TagType.Danger,
  [TransactionStatus.Failed]: TagType.Danger,
  [TransactionStatus.Pending]: TagType.Neutral,
  [TransactionStatus.Queued]: TagType.Neutral,
  [TransactionStatus.Reversed]: TagType.Neutral,
  [TransactionStatus.Success]: TagType.Primary,
}

export const TRANSACTION_STATUS_FOR_ICONS = {
  [TransactionStatus.Error]: StatusIconType.Danger,
  [TransactionStatus.Failed]: StatusIconType.Danger,
  [TransactionStatus.Pending]: StatusIconType.Info,
  [TransactionStatus.Queued]: StatusIconType.Info,
  [TransactionStatus.Reversed]: StatusIconType.Info,
  [TransactionStatus.Success]: StatusIconType.Success,
}

export const AUTH_EVENT_RESPONSES_FOR_ICONS = {
  [AuthEventResponses.Fail]: StatusIconType.Danger,
  [AuthEventResponses.InProgress]: StatusIconType.Info,
  [AuthEventResponses.Pass]: StatusIconType.Success,
}

export const DOCUMENTS_STATUS_FOR_TAGS = {
  [DocumentStatus.ACCEPT]: TagType.Secondary,
  [DocumentStatus.REJECT]: TagType.Danger,
  [DocumentStatus.UNSIGNED]: TagType.Neutral,
}

export const TASKS_STATUS_FOR_TAGS = {
  [CustomerTaskStatus.agentLocked]: TagType.Neutral,
  [CustomerTaskStatus.canceled]: TagType.Danger,
  [CustomerTaskStatus.complete]: TagType.Secondary,
  [CustomerTaskStatus.pending]: TagType.Neutral,
  [CustomerTaskStatus.submitted]: TagType.Secondary,
}

export const ENTITY_STATUS_FOR_TAGS = {
  [EntityStatus.ACTIVE]: TagType.Secondary,
  [EntityStatus.CLOSED]: TagType.Danger,
  [EntityStatus.DISABLED]: TagType.Danger,
}

export const ProductStatusTag = {
  ACTIVE: TagType.Secondary,
  CANCEL: TagType.Danger,
  DISABLED: TagType.Danger,
  INACTIVE: TagType.Danger,
  PENDING: TagType.Warning,
}

export const ProductAccountStatusTag = {
  [ProductAccountStatus.Active]: TagType.Secondary,
  [ProductAccountStatus.ActiveInFuture]: TagType.Secondary,
  [ProductAccountStatus.ActiveRenewalEligible]: TagType.Secondary,
  [ProductAccountStatus.CancellationPending]: TagType.Warning,
  [ProductAccountStatus.Cancelled]: TagType.Danger,
  [ProductAccountStatus.CancelledWithBalance]: TagType.Danger,
  [ProductAccountStatus.Expired]: TagType.Danger,
  [ProductAccountStatus.ReinstatementPending]: TagType.Warning,
}

export const ADDRESS_INPUT_VALUES = [
  'street1',
  'street2',
  'city',
  'postalCode',
  'state',
  'country',
]

export const PHONE_NUMBER_INPUT_VALUES = ['number'] as const

export const EXCLUDED_DOCUMENT_TAGS = ['servicing::v1', 'ok::y']

export const BOOLEAN_DOCUMENT_TAGS = ['agentSigned', 'signed']

export const DUPLICATE_MATCHES_REGEX_TO_REPLACE = [/%/g, /"/g, /'/g]

export const TASK_ACTIONS = [
  {
    action: 'complete',
    id: TaskStatus.complete,
    label: TaskStatus.complete,
  },
  {
    action: 'lock',
    id: TaskStatus.agentLock,
    label: TaskStatus.agentLock,
  },
  {
    action: 'snooze',
    id: TaskStatus.snooze,
    label: TaskStatus.snooze,
  },
  {
    action: 'needReview',
    id: TaskStatus.needReview,
    label: TaskStatus.needReview,
  },
  {
    action: 'cancel',
    id: TaskStatus.cancel,
    label: TaskStatus.cancel,
  },
]

export const DAYS_TO_ADD = {
  customerTaskExpiration: 14,
  policy: 183,
}

export const MONTHS_TO_ADD = {
  policy: 6,
}

export const DISABLED_SCHEDULED_TASKS = [
  PaymentsScheduleStatus.Error as string,
  PaymentsScheduleStatus.Failed as string,
  PaymentsScheduleStatus.Aborted as string,
  PaymentsScheduleStatus.Success as string,
  PaymentsScheduleStatus.Cancelled as string,
]

export const ENABLED_SCHEDULED_TASKS = [
  PaymentsScheduleStatus.Pending as string,
]

export const PAYMENT_SCHEDULE_STATUS_FOR_TAGS = {
  [PaymentsScheduleStatus.Aborted]: TagType.Neutral,
  [PaymentsScheduleStatus.Cancelled]: TagType.Danger,
  [PaymentsScheduleStatus.Error]: TagType.Danger,
  [PaymentsScheduleStatus.Failed]: TagType.Danger,
  [PaymentsScheduleStatus.Pending]: TagType.Warning,
  [PaymentsScheduleStatus.Success]: TagType.Secondary,
}

export const DISABLED_SUGGESTED_FEE_BY_ISSUER_NAME = [
  IssuerName.FalconGroup as string,
  IssuerName.FoundersMetro as string,
]

export const PRODUCT_STATUS_FOR_TAGS = {
  [ProductStatus.Active]: TagType.Secondary,
  [ProductStatus.Cancel]: TagType.Danger,
  [ProductStatus.Expired]: TagType.Danger,
}

export const FILTER_SMS_TEMPLATE_VALUES = {
  [CommunicationLogName.MANUAL.APPLICATION]: 'Application',
  [CommunicationLogName.MANUAL.OTHER]: 'Other',
  [CommunicationLogName.MANUAL.PAYMENT]: 'Payment',
  [CommunicationLogName.MANUAL.POLICY]: 'Policy',
}

export const INVALID_DATA = 'invalidData'

export const NULL_AS_HUMAN_READABLE = 'N/A'

export const PAYMENT_SUMMARY_VALUES = [
  'paymentAmount',
  'regularPaymentAmount',
  'paymentPastDueAmount',
]

export const PAYMENT_SUMMARY_VALUES_CURRENCY = [
  'paymentAmount',
  'regularPaymentAmount',
  'paymentPastDueAmount',
]

export const PAYMENT_ACCOUNTS_DEBIT_TYPE = [
  PaymentAccountsMethod.Debit as string,
]

export const AnimationSizeMap = {
  default: '70px',
  medium: '55px',
  small: '35px',
  xsmall: '15px',
}

export const TaskTypesColors = {
  [TaskTypes.Cancel]: 'danger',
  [TaskTypes.CancelCarrier]: 'danger',
  [TaskTypes.CancelCarrierConfirmation]: 'warning',
  [TaskTypes.Email]: 'warning',
  [TaskTypes.NotificationCarrier]: 'warning',
  [TaskTypes.PaymentAdvanceFailure]: 'danger',
  [TaskTypes.PaymentProductCancel]: 'danger',
  [TaskTypes.RenewalOffer]: 'primary',
}

export const METABASE_TASK_REPORT_URL =
  'https://metabase-env-prod.ocho.co/dashboard/36-dashboard-task-report-summary'

export const TaskTypesCountColorsMap = {
  danger: 'var(--color--solid-alt__danger)',
  default: 'var(--color--solid-alt__neutral)',
  naranja: 'var(--color--solid-alt__warning)',
  normal: 'var(--color--solid-alt__neutral)',
  warning: 'var(--color--solid-alt__warning)',
}

export const FINAL_QUOTE_PURPOSES = [Purpose.DownPayment, Purpose.Installment]

export const ENDORSEMENT_PURPOSES = [Purpose.Endorsement, Purpose.Installment]

export const ENDORSEMENT_TYPE_CARRIER_INITIATED = [
  EndorsementTypes.NoProofOfPrior,
  EndorsementTypes.NoProofOfAddress,
  EndorsementTypes.DriverLicenseVerification,
  EndorsementTypes.AdditionalDriversDiscovered,
  EndorsementTypes.AccidentsClaimsDiscovered,
  EndorsementTypes.ViolationsDiscovered,
] as const

export const ENDORSEMENT_TYPE_CUSTOMER_INITIATED = [
  EndorsementTypes.Reinstate,
  EndorsementTypes.AddDriver,
  EndorsementTypes.DeleteDriver,
  EndorsementTypes.AddVehicle,
  EndorsementTypes.DeleteVehicle,
  EndorsementTypes.AddLienHolder,
  EndorsementTypes.ChangeLienHolder,
  EndorsementTypes.ChangeMaritalStatus,
  EndorsementTypes.ChangeAddress,
  EndorsementTypes.ChangeCoverage,
] as const

export const TIME_ZONES = [TimeZones.UsCentral, TimeZones.UsPacific] as const

export const RECONCILIATION_TRANSACTION_STATUS_FOR_OPTIONS = [
  ReconciliationStatus.Approve,
  ReconciliationStatus.Dispute,
  ReconciliationStatus.NeedAttention,
  ReconciliationStatus.Pending,
  ReconciliationStatus.Unknown,
]

export const RECONCILIATION_TRANSACTION_STATUS_FOR_TAGS = {
  [ReconciliationStatus.Approve]: TagType.Success,
  [ReconciliationStatus.Dispute]: TagType.Danger,
  [ReconciliationStatus.NeedAttention]: TagType.Warning,
  [ReconciliationStatus.Pending]: TagType.Warning,
  [ReconciliationStatus.Unknown]: TagType.Neutral,
}

export const BANK_TRANSACTION_PURPOSE_FOR_OPTIONS = [
  TransactionPurpose.DownPayment,
  TransactionPurpose.Endorsement,
  TransactionPurpose.Installment,
  TransactionPurpose.Other,
  TransactionPurpose.RefundCancellation,
  TransactionPurpose.RefundOverPayment,
  TransactionPurpose.Reinstatement,
  TransactionPurpose.Unknown,
]

export const IVANS_TRANSACTION_PURPOSE_FOR_OPTIONS = [
  TransactionPurpose.New,
  TransactionPurpose.Cancel,
  TransactionPurpose.CancelNonPayment,
  TransactionPurpose.Reinstatement,
  TransactionPurpose.Endorsement,
  TransactionPurpose.Renewal,
  TransactionPurpose.Unknown,
  TransactionPurpose.Other,
]

export const LOAN_EVENT_TYPES_FOR_TAGS = {
  [LoanEventType.Cancel]: TagType.Danger,
  [LoanEventType.ChargeOff]: TagType.Danger,
  [LoanEventType.Create]: TagType.Primary,
  [LoanEventType.FeeCredit]: TagType.Secondary,
  [LoanEventType.FeePaymentBounced]: TagType.Warning,
  [LoanEventType.PaidOff]: TagType.Primary,
  [LoanEventType.PaycheckDateAdjustment]: TagType.Secondary,
  [LoanEventType.Payment]: TagType.Primary,
  [LoanEventType.PaymentAdjustment]: TagType.Secondary,
  [LoanEventType.PaymentReversal]: TagType.Danger,
  [LoanEventType.Reactivate]: TagType.Secondary,
  [LoanEventType.Rewrite]: TagType.Secondary,
}

export const INSURANCE_STATUS_FOR_OPTIONS = [
  { label: InsuranceStatus.Insured, value: 'true' },
  { label: InsuranceStatus.Uninsured, value: 'false' },
]

export const RATE_CALL_TYPES_FOR_COVERAGE = [RateCall.rc15] as const

export const RATE_CALL_TYPES_FOR_COMP_AND_COLL = [RateCall.rc1] as const

export const COVERAGE_TYPES = [
  CoverageType.Basic,
  CoverageType.BasicPlus,
] as const

export const CONSENT_STATUS_FOR_TAGS = {
  [ConsentStatus.accepted]: TagType.Secondary,
  [ConsentStatus.declined]: TagType.Danger,
}
