import { ApplicationStatus } from '@/utils/constants/enums'

// https://github.com/ocho-co/ocho-backend/blob/develop/ocho-common-schema/src/main/kotlin/co/ocho/common/schema/application/ApplicationAutoV1.kt

const STATE_FIELD_MAPPING = {
  [ApplicationStatus.ACCOUNT_CREATED]: [
    'drivers[].licenseIsForeign',
    'drivers[].licenseCountry',
    'drivers[].drivingRecord',
    'vehicles[].year',
    'vehicles[].make',
    'vehicles[].model',
    'vehicles[].trim',
    'vehicles[].vin',
    'vehicles[].mileage',
    'vehicles[].milesPerDay',
    'vehicles[].ownership',
    'applicant.consentTCPA',
    'applicant.consentIpTCPA',
    'applicant.consentDateTCPA',
  ],
  // specific for '*/driverVehicle' endpoint
  [ApplicationStatus.DRIVER_VEHICLE_DISCOVERY]: [
    'drivers[].givenName',
    'drivers[].familyName',
    'drivers[].middleName',
    'drivers[].dateOfBirth',
    'drivers[].age',
    'drivers[].gender',
    'drivers[].maritalStatus',
    'drivers[].relationshipStatus',
    'drivers[].drivingRecord',
    'drivers[].licenseIsForeign',
    'drivers[].licenseNumber',
    'drivers[].licenseState',
    'drivers[].licenseCountry',
    'drivers[].licenseStatus',
    'drivers[].licenseDate',
    'drivers[].licenseExpiryDate',
    'drivers[].licenseFirstAcquiredDate',
    'drivers[].occupation',
    'drivers[].educationLevel',
    'drivers[].goodDriverDiscount',
    'drivers[].goodStudentDiscount',
    'drivers[].defensiveDriverCourseDiscount',
    'drivers[].inclusionStatus',
    'drivers[].exclusionReason',

    'drivers[].incidents[].type',
    'drivers[].incidents[].subType',
    'drivers[].incidents[].code',
    'drivers[].incidents[].description',
    'drivers[].incidents[].eventDate',
    'drivers[].incidents[].convictionDate',
    'drivers[].incidents[].dataSource',
    'drivers[].incidents[].inclusionStatus',

    'vehicles[].vin',
    'vehicles[].year',
    'vehicles[].make',
    'vehicles[].model',
    'vehicles[].trim',
    'vehicles[].mileage',
    'vehicles[].milesPerDay',
    'vehicles[].ownership',
    'vehicles[].use',
    'vehicles[].garagingStreet',
    'vehicles[].garagingCity',
    'vehicles[].garagingPostalCode',
    'vehicles[].garagingState',
    'vehicles[].relatedDriver',
    'vehicles[].relatedDriverUsagePercentage',
    'vehicles[].antiTheftDiscount',
    'vehicles[].consentNonRideShareUse',
    'vehicles[].consentNonCommercialProhibitedUse',
    'vehicles[].consentNonCommercialProhibitedUse',
    'vehicles[].inclusionStatus',
    'vehicles[].lienHolderName',
    'vehicles[].lienHolderAddressStreet1',
    'vehicles[].lienHolderAddressStreet2',
    'vehicles[].lienHolderCity',
    'vehicles[].lienHolderPostalCode',
    'vehicles[].lienHolderState',

    'vehicles[].coverages[].id',
    'vehicles[].coverages[].type',
  ],
  [ApplicationStatus.ISSUER_QUESTIONS]: [
    'applicant.issuerQuestions',
    'drivers.issuerQuestions',
    'vehicles.issuerQuestions',
  ],

  // Authenticated

  // from now on '/autoFlow/applications/CurrentApplicationId/*' endpoint
  // omit the '/public'

  [ApplicationStatus.LEAD]: [
    'searchKey',
    'applicant.givenName',
    'applicant.middleName',
    'applicant.familyName',
    'applicant.dateOfBirth',
    'applicant.age',
    'applicant.addressStreet1',
    'applicant.addressStreet2',
    'applicant.city',
    'applicant.postalCode',
    'applicant.state',
    'applicant.emailAddress',
    'applicant.postalCode',
    'applicant.maritalStatus',
    'applicant.gender',
    'applicant.preferredLanguage',
    'applicant.mobilePhoneNumber',
    'applicant.currentlyInsured',
    'applicant.consentTermsAndConditions',
    'drivers[].licenseIsForeign',
    'drivers[].licenseNumber',
    'drivers[].licenseState',
    'drivers[].licenseCountry',
    'drivers[].licenseStatus',
    'drivers[].licenseDate',
    'drivers[].licenseExpiryDate',
    'drivers[].licenseFirstAcquiredDate',
    'drivers[].drivingRecord',
    'applicant.consentTCPA',
    'applicant.consentIpTCPA',
    'applicant.consentDateTCPA',
  ],

  // from now on '*/creditApplication' endpoint

  [ApplicationStatus.NEW]: [
    'searchKey',
    'applicant.givenName',
    'applicant.middleName',
    'applicant.familyName',
    'applicant.dateOfBirth',
    'applicant.age',
    'applicant.addressStreet1',
    'applicant.addressStreet2',
    'applicant.city',
    'applicant.currentlyInsured',
    'marketingSurvey.channel',
    'marketingSurvey.hearAboutUs',
    'marketingSurvey.reasonForShopping',
    'applicant.postalCode',
    'applicant.state',
    'applicant.emailAddress',
    'applicant.maritalStatus',
    'applicant.gender',
    'applicant.preferredLanguage',
    'applicant.mobilePhoneNumber',
    'drivers[].alternativeIdentificationType',
    'drivers[].drivingRecord',
    'drivers[].licenseIsForeign',
    'drivers[].licenseNumber',
    'drivers[].licenseState',
    'drivers[].licenseCountry',
    'drivers[].licenseStatus',
    'drivers[].licenseDate',
    'drivers[].licenseExpiryDate',
    'drivers[].licenseFirstAcquiredDate',
    'vehicles[].year',
    'vehicles[].make',
    'vehicles[].model',
    'vehicles[].trim',
    'vehicles[].vin',
    'vehicles[].mileage',
    'vehicles[].milesPerDay',
    'vehicles[].ownership',
    'vehicles[].use',
    'applicant.consentTCPA',
    'applicant.consentIpTCPA',
    'applicant.consentDateTCPA',
    'applicant.consentIpTermsAndConditions',
    'applicant.consentTermsAndConditions',
  ],
  [ApplicationStatus.POLICY_GENERATED]: [
    'policy.identifier',
    'policy.documentId',
  ],

  [ApplicationStatus.POLICY_SIGNATURE_PENDING]: [
    'policy.signedDocumentId',
    'policy.signature',
    'policy.initial',
    'policy.signatureTimestamp',
  ],

  [ApplicationStatus.QUOTES_FINAL]: [
    'quote.rateCallFinalId',
    'quote.rateCallFinalIssuer',
    // The next is to make up to the async update of the application status
    // after `/confirm` PATCH
    'applicant.consentBrokerFeeAcknowledgement',
    'applicant.payCheckDate',
    'applicant.payCheckFrequency',
    'applicant.preferredPaymentDay',
  ],

  [ApplicationStatus.QUOTES_FINAL_ACCEPTED]: [
    'quote.rateCallFinalId',
    'quote.rateCallFinalIssuer',
    'applicant.consentBrokerFeeAcknowledgement',
    'applicant.payCheckDate',
    'applicant.payCheckFrequency',
    'applicant.preferredPaymentDay',
    'vehicles[].lienHolderAddressStreet1',
    'vehicles[].lienHolderAddressStreet2',
    'vehicles[].lienHolderCity',
    'vehicles[].lienHolderName',
    'vehicles[].lienHolderPostalCode',
    'vehicles[].lienHolderState',
  ],

  [ApplicationStatus.QUOTES_RATE_CALL1]: [
    'quote.rateCall1Id',
    'quote.rateCall1Issuer',
    'applicant.givenName',
    'applicant.familyName',
    'applicant.middleName',
    'applicant.dateOfBirth',
    'applicant.age',
    'applicant.gender',
    'applicant.maritalStatus',
    'applicant.addressStreet1',
    'applicant.addressStreet2',
    'applicant.homeOwnership',
    'applicant.city',
    'applicant.postalCode',
    'applicant.state',
    'applicant.emailAddress',
    'applicant.ssn',
    'applicant.itin',
    'applicant.currentlyInsured',
    'applicant.consentTermsAndConditions',
    'applicant.consentCreditAuthorization',
    'applicant.consentIpCreditAuthorization',
    'applicant.consentDateCreditAuthorization',
  ],

  [ApplicationStatus.QUOTES_RATE_CALL1_5]: [
    'quote.rateCall15Id',
    'quote.rateCall15Issuer',
  ],
} as {
  [key in ApplicationStatus]: string[]
}

export default STATE_FIELD_MAPPING
